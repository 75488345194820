<div class="socialLinks justify-content-end position-fixed sideSocialLinks">
  <a href="https://www.facebook.com/people/SP-Software-Private-Ltd/100069254829452/" target="_blank"><img class="img-fluid pb-1"
      src="../../assets/images/facebook.svg" alt="facebook"></a><br />
  <a href="https://twitter.com/SPSoftGlobal" target="_blank"><img class="img-fluid pb-1"
      src="../../assets/images/twitter.svg" alt="twitter"></a><br />
  <a href="https://www.linkedin.com/company/spsoftglobal/" target="_blank"><img class="img-fluid pb-1"
      src="../../assets/images/linkedin.svg" alt="linkedin"></a><br />
  <a href="https://www.youtube.com/@spsoftglobal-company" target="_blank"><img class="img-fluid pb-1"
      src="../../assets/images/youtueb.svg" alt="youtube"></a><br />
      <a href="https://clutch.co/profile/sp-software-p#highlights" target="_blank"><img class="img-fluid"
        src="../../assets/images/clutch.png" alt="clutch" style="width: 36px;"></a><br />
</div>

<footer>
  <div class="footer py-5" id="footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <h3> We're Here </h3>
          <div class="row">
            <div class="col-lg-5">
              <h6 class="text-uppercase m-0 py-3">corporate office: india</h6>
              <p>
                SP Software (P) Limited,<br />
                "Vaishnavi's Cynosure", Unit: 4A to 4F, 4th Floor, #1-72/3/19 to 49/VC, Survey No:18,
                Gachibowli, Telangana, Hyderabad - 500 032.
              </p>

              <div class="py-3">
                <h6 class="text-uppercase">phone</h6>
                <p>+91 (40) 4448 1155</p>
              </div>
              <div class="inforMation">
                <div class="pb-3">
                  <h6 class="text-uppercase">email</h6>
                  <a href="mailto:info@spsoftglobal.com">info@spsoftglobal.com</a>
                </div>
                <div class="pb-3">
                  <h6 class="text-uppercase">jobs</h6>
                  <a href="mailto:careers@spsoftglobal.com">careers@spsoftglobal.com</a>
                  <br>
                  <a href="mailto:careers@spsoftglobal.ch">careers@spsoftglobal.ch</a>
                </div>
              </div>
            </div>
            <div class="col-lg-5">

              <h6 class="text-uppercase m-0 py-3">usa</h6>
              <p>
                SP Software LLC.,
                2880, Zanker Road, Suite 203, San Jose, California, <br>CA - 95134.
              </p>

              <h6 class="text-uppercase m-0 py-3">germany</h6>
              <p>
                SP SOFT SCHWEIZ <br>
                Hauptstrasse 33, 3800 Matten b. Interlaken.
              <div class="py-1">
                <h6 class="text-uppercase">phone</h6>
                <p>+41 76 70 20 847 </p>
              </div>

              <div class="inforMation">
                <div class="pb-3">
                  <h6 class="text-uppercase">email</h6>
                  <a href="mailto:aj@spsoftglobal.ch">aj@spsoftglobal.ch</a>
                </div>

              </div>

            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <h3> Important Links</h3>
          <div class="row">

            <div class="col-lg-6 impLinks">

              <!-- <ul class="list-unstyled">
                <li class="py-2"> <a href="#" [routerLink]="['/capabilities']">Our Core Capabilities </a> </li>
                <li class="py-2"> <a href="#" [routerLink]="['/expertise']"> Case Studies </a> </li>
                <li class="py-2"> <a href="#" [routerLink]="['/banking-service']"> Banking and Finance </a> </li>
                <li class="py-2"> <a href="#" [routerLink]="['/transport']"> Transport </a> </li>
                <li class="py-2"> <a href="#" [routerLink]="['/manufacturing']"> Manufacturing </a> </li>
                <li class="py-2"> <a href="#" [routerLink]="['/retail']"> Retail </a> </li>
                <li class="py-2"> <a href="#" [routerLink]="['/healthcare']"> Healthcare </a> </li>
              </ul> -->


              <ul class="list-unstyled m-0">
                <h6 class="text-uppercase m-0 py-3">Industries</h6>
                <li class="py-2"> <a (click)="scrollTop('banking-service')" class="pointer"> Banking and Finance </a>
                </li>
                <li class="py-2"> <a (click)="scrollTop('transport')" class="pointer"> Transport </a> </li>
                <li class="py-2"> <a (click)="scrollTop('manufacturing')" class="pointer"> Manufacturing </a> </li>
                <li class="py-2"> <a (click)="scrollTop('retail')" class="pointer"> Retail </a> </li>
                <li class="py-2"> <a (click)="scrollTop('healthcare')" class="pointer"> Healthcare </a> </li>
                <li class="py-2"><a (click)="scrollTop('telecom')" class="pointer"> Telecom </a></li>
                <li class="py-2"><a (click)="scrollTop('gaming')" class="pointer"> Gaming </a></li>
                <li class="py-2"><a (click)="scrollTop('e-learning')" class="pointer"> E-Learning </a></li>
              </ul>

            </div>

            <div class="col-lg-6 impLinks">
              <h6 class="text-uppercase m-0 py-3">Quick Links</h6>
              <!-- <ul class="list-unstyled">
                <li class="py-2"> <a href="#" [routerLink]="['/telecom']"> Telecom </a> </li>
                <li class="py-2"> <a href="#" [routerLink]="['/gaming']"> Gaming </a> </li>
                <li class="py-2"> <a href="#" [routerLink]="['/e-learning']"> E-Learning </a> </li>
                <li class="py-2"> <a href="#" [routerLink]="['/workforcesolutions']"> Workforce Solutions </a> </li>
                <li class="py-2"> <a href="#" [routerLink]="['/about-us']"> About Us</a> </li>
                <li class="py-2"> <a href="#" [routerLink]="['/career']"> Careers </a> </li>
                <li class="py-2"> <a href="#" [routerLink]="['/reach-us']"> Contact Us </a> </li>
              </ul> -->

              <ul class="list-unstyled subLinks">
                <li class="py-2"> <a (click)="scrollTop('capabilities')" class="pointer">Our Core Capabilities </a>
                </li>
                <li class="py-2"> <a (click)="scrollTop('expertise')" class="pointer"> Case Studies </a> </li>
                <li class="py-2"><a (click)="scrollTop('workforcesolutions')" class="pointer"> Workforce Solutions </a>
                </li>
                <li class="py-2"><a (click)="scrollTop('about-us')" class="pointer"> About Us </a></li>
                <li class="py-2"><a (click)="scrollTop('career')" class="pointer"> Careers </a></li>
                <li class="py-2"><a (click)="scrollTop('reach-us')" class="pointer"> Contact Us </a></li>
                <li class="py-2"><a data-bs-toggle="modal" class="pointer" data-bs-target="#boardmemeber12" #modalTrigger> Disclaimer</a></li>

              </ul>


              <!-- <p data-bs-toggle="modal" class="pointer" data-bs-target="#boardmemeber12" #modalTrigger>Disclaimer</p> -->


              <div class="sociaLink d-lg-none">
                <h3 class="m-0 pb-2">Social Links</h3>

                <ul class="d-inline p-0">
                  <a href="https://www.facebook.com/people/SP-Software-Private-Ltd/100069254829452/" target="_blank"><img
                      class="img-fluid pe-1" src="../../assets/images/facebook.svg" alt="facebook"></a>
                  <a href="https://twitter.com/SPSoftGlobal" target="_blank"><img class="img-fluid pe-1"
                      src="../../assets/images/twitter.svg" alt="twitter"></a>
                  <a href="https://www.linkedin.com/company/spsoftglobal/" target="_blank"><img class="img-fluid pe-1"
                      src="../../assets/images/linkedin.svg" alt="linkedin"></a>
                  <a href="https://www.youtube.com/@spsoftglobal-company" target="_blank"><img
                      class="img-fluid pe-1" src="../../assets/images/youtueb.svg" alt="youtube"></a>
                      <a href="https://clutch.co/profile/sp-software-p#highlights" target="_blank"><img class="img-fluid"
                        src="../../assets/images/clutch.png" alt="clutch" style="width: 36px;"></a>
                </ul>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <a id="scroll">
                <img src="../../assets/images/top.svg" alt="Image" />
              </a>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
  <div class="footer-bottom py-4">
    <div class="container d-flex justify-content-center">
      <p class="m-0"> © 2025 SP Software (P) Ltd., All Rights Reserved. </p>
    </div>
  </div>
</footer>


<div class="modal fade" id="boardmemeber12" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">

<div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable" role="document">

  <div class="modal-content">

    <div class="modal-body" style="border-bottom: 8px solid #39f;">

      <button type="button" class="btn-close pr-2 close" id="disclaimerModal" #disclaimerModal data-bs-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>

      <div class="row p-3 discontent">

        <div class="col-lg-24 text-center">

          <!-- <div class="board-semhead font-weight-bold">DISCLAIMER</div>
          <hr class="discclaiBdr"> -->


          <div class="board-semhead font-weight-bold">

            <h4 class="m-0 pb-2">DISCLAIMER</h4>
        <hr class="discclaiBdr mb-1">

        </div>

            <p class="member-designation-content mb-2">
              We bring to the notice of all concerned that we, i.e., SP Software (P) Limited, have deployed a merit-based employee selection practice. We do not charge or accept any amount or security deposit from job seekers during the selection process or while inviting candidates for an interview. Any party posing on behalf of SP Software (P) Limited and collecting money from candidates, promising them jobs is doing so with fraudulent intent and wrongfully using the company name.
            </p>
            <p>
              In case you are demanded money for a job by an SP Software (P) Limited employee, affiliated party, or consultant, please bring it to our notice by writing to
            </p>
      
              <a href="mailto:info@spsoftglobal.com">info@spsoftglobal.com</a>


        </div>

      </div>
    </div>
  </div>
</div>
</div>
