import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Loader Service
 * @export
 * @class LoaderService
 */
@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  /**
   * Used to set the Loader Display or not
   */
  isLoading = new BehaviorSubject<boolean>(false);

}
