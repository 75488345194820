import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @ViewChild('modalTrigger') modalTrigger: ElementRef;
  @ViewChild('disclaimerModal') modalClose: ElementRef;

  constructor(private route: Router) { }

 

  ngAfterViewInit() {
    // if (this.route.url === '/') {
    //   this.modalTrigger.nativeElement.click();
    //   setTimeout(() =>{
    //     this.modalClose.nativeElement.click();
    //   }, 10000);
    // }
  }

  ngOnInit() {
    
    $(document).ready(function () {
      const btn = $('#scroll');
      btn.on('click', function (e: any) {
        e.preventDefault();
        $('html, body').animate({ scrollTop: '0' }, 500);
        btn.removeClass('show');
      });

    });

  }

  scrollTop(path: any) {
    $('html, body').animate({ scrollTop: '0' });

    this.route.navigateByUrl('/' + path);
  }

}
