<nav class="navbar fixed-top navbar-expand-lg navbar-light transparentNav">
  <div class="container-fluid">
    <a class="navbar-brand" href="/"><img src="../../assets/images/spsoft.svg" alt="logo"></a>
 
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!--responsive menu -->
    <div class="mobilemenu">
     <img src="../../assets/images/aws.svg" class="awsMobileView pe-1" alt="" [routerLink]="['/aws-services']">
      <a class="nav-link" (click)="showMenu()"><img src="assets/images/toggle.svg" alt="menu" /></a>
    </div>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">

        <li class="nav-item my-auto pe-0" style="cursor: pointer;">
          <a class="nav-link py-0" [routerLink]="['/prompt-engineering']"><img src="assets/images/amzon.svg" alt="menu" class="aws"/></a>
        </li>

        <li class="nav-item my-auto pe-0" style="cursor: pointer;">
          <a class="nav-link py-0" [routerLink]="['/aws-services']"><img src="assets/images/aws.svg" alt="menu" class="aws"/></a>
        </li>

        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/career']" aria-current="page">Careers</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/about-us']" href="#">About Us</a>
        </li>
        <li class="nav-item my-auto">
          <a class="nav-link contactBtn" href="#" target="_blank" [routerLink]="['/reach-us']">Contact Us</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link contactBtn" href="http://192.168.10.241:90/sign-in" target="_blank" >Login</a>
        </li> -->

        <!-- Menu -->
        <li class="nav-item my-auto pe-0" style="cursor: pointer;">
          <a class="nav-link" (click)="showMenu()"><img src="assets/images/toggle.svg" alt="menu" /></a>
        </li>
      </ul>
    </div>
  </div>
</nav>


<!-- For Mobile Menu -->

<div class="mobilemenu" *ngIf="menu">

  <div class="row">


    <div [ngClass]="[childMenu ? 'col-md-7 col-7' : 'col-md-9 col-9']" (click)="showMenu()"></div>

    <!-- <div [ngClass]="[childMenu ? 'col-md-5 col-5 d-flex' : 'col-md-12 col-12']" style="background: #051C2D;
    opacity: 0.95; height:100vh;
    position: relative;z-index: 99999;"> -->

    <div [ngClass]="[childMenu ? 'col-md-5 col-5 d-flex' : 'col-md-12 col-12']" style="background: #051C2D; height:100vh;
position: relative;z-index: 99999;">



      <!-- Parent Menu -->
      <div class="px-3" [ngClass]="[childMenu ? 'col-md-6 col-6' : '']" style="height: 102vh;
      overflow: auto;">

        <div class="my-3 d-flex justify-content-between">
          <div>
            <a href="#" style="cursor: pointer;" (click)="showMenu()"><img src="assets/images/menu/logowhite.png"
                alt="Image" /></a>
          </div>
          <div class="my-auto">
            <a style="cursor: pointer;" (click)="showMenu()"><img src="../../assets/images/cross.svg" alt="Image" /></a>
          </div>
        </div>

        <div class="mt-4">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item menu-links" (click)="hideChildMenu()">
              <a class="nav-link ps-2" [routerLink]="['/']" aria-current="page" href="#"
                (click)="showMenu()">Home</a>
            </li>

            <li class="nav-item menu-links" (click)="hideChildMenu()">
              <a class="nav-link ps-2" [routerLink]="['/capabilities']" aria-current="page" href="#"
                (click)="showMenu()">Our Core Capabilities</a>
            </li>

            <li class="nav-item menu-links" (click)="hideChildMenu()">
              <a class="nav-link ps-2" [routerLink]="['/expertise']" aria-current="page" href="#"
                (click)="showMenu()">Case Studies</a>
            </li>

            <li class="nav-item menu-links">
              <!-- Accordion toggle -->
              <a class="nav-link ps-2" aria-current="page" (click)="toggleAccordion('services-list')">
               Services
              </a>
              <!-- Accordion content -->
              <div class="accordion-item" [ngClass]="{'show': isAccordionOpen('services-list')}">
                <div class="accordion-body">
                  <!-- Add your Services content here -->
                  <li><a [routerLink]="['/ai-ml-services']" (click)="closeAccordion()">AI Development</a></li>
                  <li><a [routerLink]="['/data-analytics-services']" (click)="closeAccordion()">Data Analytics</a></li>
                      <li><a [routerLink]="['/application-development-maintenance-services']" (click)="closeAccordion()">Application Development</a></li>
                        <li><a [routerLink]="['/mobile-app-development-services']" (click)="closeAccordion()">Mobile App Development</a></li>
                          <li><a [routerLink]="['/web-development-services']" (click)="closeAccordion()">Web Development</a></li>
                            <li><a [routerLink]="['/testing-services']" (click)="closeAccordion()">Testing</a></li>
                              <li><a [routerLink]="['/digital-marketing-services']" (click)="closeAccordion()">Digital Marketing</a></li>
                                <li><a [routerLink]="['/workforcesolutions']" (click)="closeAccordion()">Workforce solutions</a></li>
                                <li><a [routerLink]="['/prompt-engineering']" (click)="closeAccordion()">Prompt Engineering</a></li>
                                <li><a [routerLink]="['/aws-services']" (click)="closeAccordion()">AWS</a></li>

                  <!-- More Services -->
                </div>
              </div>
            </li>

            <li class="nav-item menu-links">
              <!-- Accordion toggle -->
              <a class="nav-link ps-2" aria-current="page" (click)="toggleAccordion('Industries-list')">
               Industries
              </a>
              <!-- Accordion content -->
              <div class="accordion-item" [ngClass]="{'show': isAccordionOpen('Industries-list')}">
                <div class="accordion-body">
                  <!-- Add your Industries content here -->
                                <li><a [routerLink]="['/banking-service']" (click)="closeAccordion()">Banking</a></li>
                                <li><a [routerLink]="['/transport']" (click)="closeAccordion()">Transport</a></li>
                                  <li><a [routerLink]="['/manufacturing']" (click)="closeAccordion()">Manufacturing</a></li>
                                    <li><a [routerLink]="['/retail']" (click)="closeAccordion()">Retail</a></li>
                                      <li><a [routerLink]="['/healthcare']" (click)="closeAccordion()">HealthCare</a></li>
                                        <li><a [routerLink]="['/telecom']" (click)="closeAccordion()">Telecom</a></li>
                                          <li><a [routerLink]="['/gaming']" (click)="closeAccordion()">Gaming</a></li>
                                            <li><a [routerLink]="['/e-learning']" (click)="closeAccordion()">E-Learning</a></li>
                  <!-- More Industries -->
                </div>
              </div>
            </li>

            <!-- <li class="nav-item menu-links" (click)="hideChildMenu()">
              <a class="nav-link ps-2" [routerLink]="['/workforcesolutions']" aria-current="page" href="#"
                (click)="showMenu()">Workforce Solutions</a>
            </li> -->

            <!-- <li class="nav-item menu-links" (click)="hideChildMenu()">
              <a class="nav-link ps-2" [routerLink]="['/banking-service']" aria-current="page" href="#"
                (click)="showMenu()">Banking</a>
            </li> -->

            <!-- <li class="nav-item menu-links">
              <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/transport']"
                (click)="showMenu()">Transport</a>
            </li> -->
            <!-- <li class="nav-item menu-links">
              <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/manufacturing']"
                (click)="showMenu()">Manufacturing</a>
            </li>
            <li class="nav-item menu-links">
              <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/retail']"
                (click)="showMenu()">Retail</a>
            </li>
            <li class="nav-item menu-links">
              <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/healthcare']"
                (click)="showMenu()">HealthCare</a>
            </li>
            <li class="nav-item menu-links">
              <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/telecom']"
                (click)="showMenu()">Telecom</a>
            </li>
            <li class="nav-item menu-links">
              <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/gaming']"
                (click)="showMenu()">Gaming</a>
            </li>
            <li class="nav-item menu-links">
              <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/e-learning']"
                (click)="showMenu()">E-Learning</a>
            </li> -->
          
            <li class="nav-item menu-links">
              <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/offshore-development-services']"
                (click)="showMenu()">OffShore Development</a>
            </li>
            <li class="nav-item menu-links">
              <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/gallery']"
                (click)="showMenu()">Gallery</a>
            </li>
            <!-- <li class="nav-item menu-links">
              <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/mobile-app-development-services']"
                (click)="showMenu()">Mobile App Development</a>
            </li>
            <li class="nav-item  menu-links">
              <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/web-development-services']"
                (click)="showMenu()">Web Development</a>
            </li>
            <li class="nav-item menu-links ">
              <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/ai-ml-services']"
                (click)="showMenu()">AI Development</a>
            </li>
            <li class="nav-item menu-links">
              <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/data-analytics-services']"
                (click)="showMenu()">Data Analytics</a>
            </li>
            <li class="nav-item menu-links">
              <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/application-development-maintenance-services']"
                (click)="showMenu()">Application Development</a>
            </li>
            <li class="nav-item  menu-links">
              <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/testing-services']"
                (click)="showMenu()">Testing</a>
            </li>
            <li class="nav-item  menu-links">
              <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/digital-marketing-services']"
                (click)="showMenu()">Digital Marketing</a>
            </li> -->

            <li class="nav-item menu-links " (click)="hideChildMenu()">
              <a class="nav-link ps-2" [routerLink]="['/about-us']" aria-current="page" href="#"
                (click)="showMenu()">About Us</a>
            </li>
            <li class="nav-item menu-links" (click)="hideChildMenu()">
              <a class="nav-link ps-2" [routerLink]="['/career']" aria-current="page" (click)="showMenu()">Careers</a>
            </li>
            <li class="nav-item menu-links mb-4" (click)="hideChildMenu()">
              <a class="nav-link ps-2" [routerLink]="['/reach-us']" aria-current="page" href="#"
                (click)="showMenu()">Contact Us</a>
            </li>
          </ul>
        </div>

      </div>

    </div>

  </div>



</div>


<!-- For Desktop Menu -->
<div class="container-fluid" *ngIf="menu">
  <div class="row">
    <div [ngClass]="[childMenu ? 'col-md-7 col-7' : 'col-md-9 col-9']" (click)="showMenu()"></div>
    <div [ngClass]="[childMenu ? 'col-md-5 col-5 d-flex' : 'col-md-3 col-3']" class="px-0"
      style="background: #051C2D; opacity: 0.95; height:100vh; position: relative; z-index: 99999;">

      <!-- Child Menu -->
      <div class="col-md-6 col-6 p-3 pt-5 togglechildMenu" *ngIf="childMenu">
        <div class="mt-5">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <!-- Industries Child Menu -->
            <div *ngIf="activeMenu === 'industries'">
              <li class="nav-item child-links py-2">
                <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/banking-service']"
                  (click)="showMenu()">Banking</a>
              </li>
              <li class="nav-item child-links py-2">
                <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/transport']"
                  (click)="showMenu()">Transport</a>
              </li>
              <li class="nav-item child-links py-2">
                <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/manufacturing']"
                  (click)="showMenu()">Manufacturing</a>
              </li>
              <li class="nav-item child-links py-2">
                <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/retail']"
                  (click)="showMenu()">Retail</a>
              </li>
              <li class="nav-item child-links py-2">
                <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/healthcare']"
                  (click)="showMenu()">HealthCare</a>
              </li>
              <li class="nav-item child-links py-2">
                <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/telecom']"
                  (click)="showMenu()">Telecom</a>
              </li>
              <li class="nav-item child-links py-2">
                <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/gaming']"
                  (click)="showMenu()">Gaming</a>
              </li>
              <li class="nav-item child-links py-2">
                <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/e-learning']"
                  (click)="showMenu()">E-Learning</a>
              </li>
            </div>
            <!-- Service Child Menu -->
            <div *ngIf="activeMenu === 'service'">
              <li class="nav-item child-links py-2">
                <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/ai-ml-services']"
                  (click)="showMenu()">AI Development</a>
              </li>
              <li class="nav-item child-links py-2">
                <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/data-analytics-services']"
                  (click)="showMenu()">Data Analytics</a>
              </li>
              <li class="nav-item child-links py-2">
                <a class="nav-link ps-2" aria-current="page" href="#"
                  [routerLink]="['/application-development-maintenance-services']" (click)="showMenu()">Application Development</a>
              </li>
              <li class="nav-item child-links py-2">
                <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/mobile-app-development-services']"
                  (click)="showMenu()">Mobile App Development</a>
              </li>
              <li class="nav-item child-links py-2">
                <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/web-development-services']"
                  (click)="showMenu()">Web Development</a>
              </li>
              <li class="nav-item child-links py-2">
                <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/testing-services']"
                  (click)="showMenu()">Testing</a>
              </li>
              <li class="nav-item child-links py-2">
                <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/digital-marketing-services']"
                  (click)="showMenu()">Digital Marketing</a>
              </li>

              <li class="nav-item child-links py-2">
                <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/workforcesolutions']"
                  (click)="showMenu()">Workforce solutions</a>
              </li>

              <li class="nav-item child-links py-2">
                <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/prompt-engineering']"
                  (click)="showMenu()">Prompt Engineering</a>
              </li>


              <li class="nav-item child-links py-2">
                <a class="nav-link ps-2" aria-current="page" href="#" [routerLink]="['/aws-services']"
                  (click)="showMenu()">AWS</a>
              </li>





            </div>
          </ul>
        </div>
      </div>

      <!-- Parent Menu -->
      <div class="px-3" [ngClass]="[childMenu ? 'col-md-6 col-6' : '']">
        <div class="my-3 d-flex justify-content-end">
          <div class="my-auto">
            <a style="cursor: pointer;" (click)="showMenu()"><img src="../../assets/images/cross.svg" alt="Image" /></a>
          </div>
        </div>
        <div class="mt-3">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item menu-links py-2" (click)="hideChildMenu()">
              <a class="nav-link ps-2" aria-current="page" href="" (click)="showMenu()">Home</a>
            </li>
            <li class="nav-item menu-links py-2" (click)="hideChildMenu()">
              <a class="nav-link ps-2" [routerLink]="['/capabilities']" aria-current="page" href="#"
                (click)="showMenu()">Our Core Capabilities</a>
            </li>
            <li class="nav-item menu-links py-2" (click)="showChildMenu('industries')">
              <a class="nav-link ps-2" aria-current="page" style="cursor:pointer">Industries</a>
            </li>
            <li class="nav-item menu-links py-2" (click)="showChildMenu('service')">
              <a class="nav-link ps-2" aria-current="page" style="cursor:pointer">Services</a>
            </li>
            <li class="nav-item menu-links py-2" (click)="hideChildMenu()">
              <a class="nav-link ps-2" [routerLink]="['/expertise']" aria-current="page" href="#"
                (click)="showMenu()">Case Studies</a>
            </li>
            <li class="nav-item menu-links py-2" (click)="hideChildMenu()">
              <a class="nav-link ps-2" [routerLink]="['/offshore-development-services']" aria-current="page" href="#"
                (click)="showMenu()">OffShore Development</a>
            </li>
            <li class="nav-item menu-links py-2" (click)="hideChildMenu()">
              <a class="nav-link ps-2" [routerLink]="['/gallery']" aria-current="page" href="#"
                (click)="showMenu()">Gallery</a>
            </li>
            <li class="nav-item menu-links py-2" (click)="hideChildMenu()">
              <a class="nav-link ps-2" [routerLink]="['/about-us']" aria-current="page" href="#"
                (click)="showMenu()">About Us</a>
            </li>
            <li class="nav-item menu-links py-2" (click)="hideChildMenu()">
              <a class="nav-link ps-2" [routerLink]="['/career']" aria-current="page" (click)="showMenu()">Careers</a>
            </li>
            <li class="nav-item menu-links py-2" (click)="hideChildMenu()">
              <a class="nav-link ps-2" [routerLink]="['/reach-us']" aria-current="page" href="#"
                (click)="showMenu()">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>




<div class="socialLinks justify-content-end position-fixed">
  <a href="https://api.whatsapp.com/send?phone=9281037141&text=Hi Spsoft! Can you help me" target="_blank"><img
      class="img-fluid pb-1" src="../../assets/images/whatsup.svg" alt="whatsup"></a><br />
</div>