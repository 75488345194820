import { Component } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  private openAccordion: string | null = null;

  /**
   * Default menu is hide
   */
  menu = false;

  /**
   * Default child menu display is hide
   */
  childMenu = false;

  /**
   * Tracks which menu is active (industries or service)
   */
  activeMenu: 'industries' | 'service' | null = null;

  showMenu() {
    this.menu = !this.menu;
    this.childMenu = false;
    this.activeMenu = null;
  }

  showChildMenu(menuType: 'industries' | 'service') {
    if (this.activeMenu === menuType) {
      this.childMenu = !this.childMenu;
    } else {
      this.activeMenu = menuType;
      this.childMenu = true;
    }
  }

  hideChildMenu() {
    this.childMenu = false;
    this.activeMenu = null;
    this.openAccordion = null;
  }

  toggleAccordion(id: string): void {
    this.openAccordion = this.openAccordion === id ? null : id;
  }

  isAccordionOpen(id: string): boolean {
    return this.openAccordion === id;
  }

  closeAccordion(): void {
    this.menu = !this.menu;
    this.childMenu = false;
    this.activeMenu = null;
    this.openAccordion = null; // Close the accordion when a case study is clicked
  }



}
