import { Component, OnInit } from '@angular/core';
import { Unsubscribable } from 'rxjs';
import { LoaderService } from 'src/app/Services/loader.service';

/**
 * Loader Component
 * @export
 * @class LoaderComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  /**
   * Used to display loader based on boolean value
   * @type {boolean}
   */
  loader!: boolean;

  /**
   * Used to unsubscribe the value based on loader condition
   * @type {Unsubscribable}
   */
  unSub: Unsubscribable | undefined;

  /**
   * Creates an instance of LoaderComponent.
   * @param {LoaderService} loaderService
   */
  constructor(private loaderService: LoaderService) { }

  /**
   * Lifecycle hook method for default initialization
   */
  ngOnInit(): void {
    /* Retrieve value from loader service */
    this.unSub = this.loaderService.isLoading.subscribe(val => {
      this.loader = val;
    });
  }

  /**
   * Called once, before the instance is destroyed.
   * Add 'implements OnDestroy' to the class.
   */
  ngOnDestroy(): void {
    this.unSub!.unsubscribe();
  }

}
