<div class="expertiseHeader">
  <div class="container">
    <div class="custom-container">
      <div class="row pt-5">
        <div class="py-5">
          <h6 class="text-uppercase pt-5"><a class="text-decoration-none fontBold" href="">Home</a> &nbsp;&nbsp;>>
            &nbsp;&nbsp;<a class="text-decoration-none fontBold" [routerLink]="['/capabilities']">services we
              offer</a>&nbsp;&nbsp;>> <span class="caseStudyDisplay">&nbsp;&nbsp;</span><span>ai and ml</span>
          </h6>
          <h2 class="text-uppercase py-4">Retail video analytics</h2>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="comingSoonGrid">
  <div class="container">
    <div class="custom-container py-5">
        <h6 class="text-white text-capitalize text-center py-5"> Coming Soon</h6>
    </div>
  </div>
</div>